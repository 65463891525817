<template>
  <div class="page">
    <Head :headTitle="headTitle" />
    <div class="b-container">
      <div class="con-l">
        <div class="mod1 flex flex-v">
          <div class="b-pub-tt flex">
            <span class="bt">工业和建筑业发展状况</span>
          </div>
          <div class="mod2-b">
            <div class="col">
               <div class="b-smtit">
                <span class="bt">2018-2022年工业和建筑业增加值</span>
              </div>
               <div class="chart" ref="chart8"></div>
            </div>
          </div>
          <div class="mod1-b flex-11">
            <div class="col col1">
              <div class="b-smtit">
                <span class="bt">2022年规上工业增加值比重</span>
              </div>
              <div class="chart" ref="chart1"></div>
            </div>
            <div class="col col2">
              <div class="b-smtit">
                <span class="bt">工业产能发展趋势</span>
              </div>
              <div class="chart" ref="chart2"></div>
            </div>
          </div>
        </div>
        <div class="mod3">
          <div class="b-pub-tt flex">
            <span class="bt">隆安县工业基地掠影</span>
          </div>
          <ul class="list">
            <li class="item1" v-for="item in xiangcunList" :key="item.img">
              <div class="imgbox">
                <img :src="item.img" class="img img_full" alt="" />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="con-c flex flex-v relative">
        <div class="mod-cen">
          <div class="cen-menu">
            <span class="lk">智慧城市</span>
            <span class="lk">数字乡村振兴</span>
            <span class="lk cur">智慧工业溯源平台</span>
            <span class="lk">智慧文旅</span>
          </div>
          <div class="cen-top">
            <li>
              <span class="tit">2022城镇新增就业人员(万)</span>
              <p class="num">{{ webData.people }}</p>
            </li>
            <li class="line"></li>
            <li>
              <span class="tit">2022人均生产总值</span>
              <p class="num">{{ webData.grossAnnVal }}</p>
            </li>
            <li class="line"></li>
            <li>
              <span class="tit">2022工业产值(亿元)</span>
              <p class="num">{{ webData.annualGro }}</p>
            </li>
          </div>

          <div class="mod2-map">
            <!-- <img src="../assets/images/qdmap.png" class="pic" alt="" /> -->
            <div class="item item1" :style="randomNum == 0 ? 'opacity:1;' : ''">
              <div class="b-smtit">
                <span class="bt">在 途 产 品 数</span>
              </div>
              <div class="txt">
                <p>
                  <span class="tit">本月已发产品数</span>
                  <em>{{ ProductTra.amount }}吨</em>
                </p>
                <p>
                  <span class="tit">本月待发产品数</span>
                  <em>{{ ProductTra.quantity }}吨</em>
                </p>
              </div>
            </div>
            <div class="item item2" :style="randomNum == 1 ? 'opacity:1;' : ''">
              <div class="b-smtit">
                <span class="bt"> 加 工 工 厂</span>
              </div>
              <div class="txt">
                <p>
                  <span class="tit">加工工厂数量</span>
                  <em>{{ industrialPark.amount }}间</em>
                </p>
                <p>
                  <span class="tit">加工产品种类</span>
                  <em>{{ industrialPark.quantity }}种</em>
                </p>
                <p>
                  <span class="tit">产品数量</span>
                  <em>{{ industrialPark.variety }}吨</em>
                </p>
              </div>
            </div>
            <div class="item item3" :style="randomNum == 2 ? 'opacity:1;' : ''">
              <div class="b-smtit">
                <span class="bt"> 工 业 园 区</span>
              </div>
              <div class="txt">
                <p>
                  <span class="tit">工业园数量</span>
                  <em>{{ millworkPlant.amount }}间</em>
                </p>
                <p>
                  <span class="tit">工业园产品种类</span>
                  <em>{{ millworkPlant.quantity }}种</em>
                </p>
                <p>
                  <span class="tit">产品数量</span>
                  <em>{{ millworkPlant.variety }}吨</em>
                </p>
              </div>
            </div>
          </div>
          <div class="mod2-bot">
            <div class="col col1">
              <div class="b-smtit">
                <span class="bt">货物运输方式</span>
              </div>
              <div class="chart" ref="chart3"></div>
            </div>
            <div class="col col2 flex flex-v">
              <div class="b-smtit">
                <span class="bt">著名企业列表</span>
              </div>
              <div class="scroll-list flex-1 flex flex-v">
                <div class="row-t">
                  <div style="width: 40%">公司名称</div>
                  <div style="width: 35%">所属行业</div>
                  <div style="width: 25%">第一季度(亿)</div>
                </div>
                <div class="scrollbb">
                  <vue-seamless-scroll
                    :data="factoryList"
                    :class-option="scrollOption"
                    class="scrollb"
                  >
                    <div class="scrollbox flex-1">
                      <div
                        class="row"
                        v-for="(item, index) in factoryList"
                        :key="index"
                      >
                        <div style="width: 43%">{{ item.title }}</div>

                        <div style="width: 35%">{{ item.cata }}</div>
                        <div style="width: 22%">{{ item.amount }}</div>
                      </div>
                    </div>
                  </vue-seamless-scroll>
                  <span class="linebg"></span>
                </div>
              </div>
            </div>
            <div class="col col3 flex flex-v">
              <div class="b-smtit">
                <span class="bt"> 开 发 产 业 园 区</span>
              </div>
              <div class="scroll-list flex-1 flex flex-v">
                <div class="row-t">
                  <div style="width: 45%">园区名称</div>
                  <div style="width: 30%">大约面积(亩)</div>
                  <div style="width: 25%">企业数量</div>
                </div>
                <div class="scrollbb">
                  <vue-seamless-scroll
                    :data="yuanquList"
                    :class-option="scrollOption"
                    class="scrollb"
                  >
                    <div class="scrollbox flex-1">
                      <div
                        class="row"
                        v-for="(item, index) in yuanquList"
                        :key="index"
                      >
                        <div style="width: 48%">{{ item.title }}</div>

                        <div style="width: 30%">{{ item.cata }}</div>
                        <div style="width: 22%">{{ item.amount }}</div>
                      </div>
                    </div>
                  </vue-seamless-scroll>
                  <span class="linebg"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="con-r">-->
<!--        <div class="mod4 flex flex-v">-->
<!--          <div class="b-pub-tt flex">-->
<!--            <span class="bt">隆安县电商概况</span>-->
<!--          </div>-->
<!--          <div class="mod4-b flex-11">-->
<!--            <div class="col col1">-->
<!--              <div class="b-smtit">-->
<!--                <span class="bt">历 史 进 出 口 金 额 统 计(万美元)</span>-->
<!--              </div>-->
<!--              <div class="chart" ref="chart4"></div>-->
<!--            </div>-->
<!--            <div class="col col2">-->
<!--              <div class="b-smtit">-->
<!--                <span class="bt">历 史 9610 出 口 走 势</span>-->
<!--              </div>-->
<!--              <div class="chart" ref="chart5"></div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="mod4-b flex-11">-->
<!--            <div class="col col1">-->
<!--              <div class="b-smtit">-->
<!--                <span class="bt">历 史 进 出 口 票 数 统 计(万件)</span>-->
<!--              </div>-->
<!--              <div class="chart" ref="chart6"></div>-->
<!--            </div>-->
<!--            <div class="col col2">-->
<!--              <div class="b-smtit">-->
<!--                <span class="bt">企 业 金 额 排 名(美元)</span>-->
<!--              </div>-->
<!--              <ul class="bar-list">-->
<!--                <li-->
<!--                  class="row"-->
<!--                  v-for="(item, index) in chucunshuliangList"-->
<!--                  :key="index"-->
<!--                >-->
<!--                  <span class="num">{{ index + 1 }}</span>-->
<!--                  <div class="smbox">-->
<!--                    <div class="font">-->
<!--                      <span>{{ item.name }}</span>-->
<!--                      <span style="color: #fc9d1f">{{ item.num }}</span>-->
<!--&lt;!&ndash;                      <span>美元</span>&ndash;&gt;-->
<!--                    </div>-->
<!--                    <div class="progress">-->
<!--                      <i :style="{ width: item.widthVar }"></i>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="mod5 flex flex-v">-->
<!--          <div class="b-pub-tt flex">-->
<!--            <span class="bt">配套基础设施建设</span>-->
<!--          </div>-->
<!--          <div class="b-smtit">-->
<!--            <span class="bt">现状和发展趋势</span>-->
<!--          </div>-->
<!--          <ul class="mod5-gk">-->
<!--            <li>-->
<!--              <img src="../assets/images/sy_15.png" class="icon" alt="" />-->
<!--              <div class="info">-->
<!--                <h6 class="tit">2023前九月机场旅客吞吐量</h6>-->
<!--                <p class="num" style="color: #489ffe">-->
<!--                  {{ warehouseQuantity.totalWar }}万人次-->
<!--                </p>-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <img src="../assets/images/sy_16.png" class="icon" alt="" />-->
<!--              <div class="info">-->
<!--                <h6 class="tit">飞机途径航线</h6>-->
<!--                <p class="num" style="color: #85b1ff">-->
<!--                  {{ warehouseQuantity.totalWarehouseArea }}多条-->
<!--                </p>-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <img src="../assets/images/sy_17.png" class="icon" alt="" />-->
<!--              <div class="info">-->
<!--                <h6 class="tit">通航城市</h6>-->
<!--                <p class="num" style="color: #90ffad">-->
<!--                  {{ warehouseQuantity.warehouseSto }}座-->
<!--                </p>-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <img src="../assets/images/sy_18.png" class="icon" alt="" />-->
<!--              <div class="info">-->
<!--                <h6 class="tit">2022新建通车里程</h6>-->
<!--                <p class="num" style="color: #ffc55a">-->
<!--                  {{ warehouseQuantity.quantitySentAndRec }}公里-->
<!--                </p>-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <img src="../assets/images/sy_19.png" class="icon" alt="" />-->
<!--              <div class="info">-->
<!--                <h6 class="tit">新建中邮驿站+乡镇运输服务站</h6>-->
<!--                <p class="num" style="color: #45f5ff">-->
<!--                  {{ warehouseQuantity.dockingCity }}个-->
<!--                </p>-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <img src="../assets/images/sy_19.png" class="icon" alt="" />-->
<!--              <div class="info">-->
<!--                <h6 class="tit">新建交邮合作代运线路</h6>-->
<!--                <p class="num" style="color: #ffc55a">-->
<!--                  {{ warehouseQuantity.companyNum }}条-->
<!--                </p>-->
<!--              </div>-->
<!--            </li>-->
<!--          </ul>-->
<!--          <div class="mod5-sl">-->
<!--            <div class="col col1">-->
<!--              <div class="b-smtit">-->
<!--                <span class="bt">冷链仓库发展情况</span>-->
<!--              </div>-->
<!--              <div class="hang">-->
<!--                2020建成冷链物流园项目 <span>{{ warehouseQua.freeWar }}</span> 个-->
<!--              </div>-->
<!--              <div class="hang">-->
<!--                库存容量 <span>{{ warehouseQua.isotemperatureWar }}</span>万吨-->
<!--              </div>-->
<!--              <div class="hang">-->
<!--                企业入驻冷链物流基地数量 <span>{{ warehouseQua.refrigeratedWar }}</span> 家-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col col3">-->
<!--              <div class="b-smtit">-->
<!--                <span class="bt">固定资产投资占比</span>-->
<!--              </div>-->
<!--              <div class="chart" ref="chart7"></div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="con-r">
        <div class="mod4 flex flex-v">
          <div class="b-pub-tt flex">
            <span class="bt">隆安县物流产业概况</span>
          </div>
          <div class="mod4-b flex-11">
            <div class="col col1">
              <div class="b-smtit">
                <span class="bt">物 流 公 司 占 比 图 表</span>
              </div>
              <div class="chart" ref="chart4"></div>
            </div>
            <div class="col col2">
              <div class="b-smtit">
                <span class="bt">物 流 产 业 年 度 发 展 趋 势</span>
              </div>
              <div class="chart" ref="chart5"></div>
            </div>
          </div>
          <div class="mod4-b flex-11">
            <div class="col col1">
              <div class="b-smtit">
                <span class="bt">隆安县占比图表</span>
              </div>
              <div class="chart" ref="chart6"></div>
            </div>
            <div class="col col2">
              <div class="b-smtit">
                <span class="bt">各 物 流 收 发 量 每 日 排 名</span>
              </div>
              <ul class="bar-list">
                <li
                    class="row"
                    v-for="(item, index) in chucunshuliangList"
                    :key="index"
                >
                  <span class="num">{{ index + 1 }}</span>
                  <div class="smbox">
                    <div class="font">
                      <span>{{ item.name }}</span>
                      <span style="color: #fc9d1f">{{ item.num }}</span>
                      <span>万件</span>
                    </div>
                    <div class="progress">
                      <i :style="{ width: item.widthVar }"></i>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mod5 flex flex-v">
          <div class="b-pub-tt flex">
            <span class="bt">仓储概况</span>
          </div>
          <div class="b-smtit">
            <span class="bt">仓库数据统计</span>
          </div>
          <ul class="mod5-gk">
            <li>
              <img src="../assets/images/sy_15.png" class="icon" alt="" />
              <div class="info">
                <h6 class="tit">县级仓储物流分拣中心</h6>
                <p class="num" style="color: #489ffe">
                  {{ warehouseQuantity.totalWar }}平方
                </p>
              </div>
            </li>
            <li>
              <img src="../assets/images/sy_16.png" class="icon" alt="" />
              <div class="info">
                <h6 class="tit">农产品仓储配送中心</h6>
                <p class="num" style="color: #85b1ff">
                  {{ warehouseQuantity.totalWarehouseArea }}平方
                </p>
              </div>
            </li>
            <li>
              <img src="../assets/images/sy_17.png" class="icon" alt="" />
              <div class="info">
                <h6 class="tit">冷链仓储中心</h6>
                <p class="num" style="color: #90ffad">
                  {{ warehouseQuantity.warehouseSto }}平方
                </p>
              </div>
            </li>
            <li>
              <img src="../assets/images/sy_18.png" class="icon" alt="" />
              <div class="info">
                <h6 class="tit">乡镇级电商公共服务</h6>
                <p class="num" style="color: #ffc55a">
                  {{ warehouseQuantity.quantitySentAndRec }}个
                </p>
              </div>
            </li>
            <li>
              <img src="../assets/images/sy_19.png" class="icon" alt="" />
              <div class="info">
                <h6 class="tit">村级电商公共服务站</h6>
                <p class="num" style="color: #45f5ff">
                  {{ warehouseQuantity.dockingCity }}个
                </p>
              </div>
            </li>
            <li>
              <img src="../assets/images/sy_19.png" class="icon" alt="" />
              <div class="info">
                <h6 class="tit">农产品加工企业</h6>
                <p class="num" style="color: #ffc55a">
                  {{ warehouseQuantity.companyNum }}个
                </p>
              </div>
            </li>
          </ul>
          <div class="mod5-sl">
            <div class="col col1">
              <div class="b-smtit">
                <span class="bt">仓库数量</span>
              </div>
              <div class="hang">
                普通仓库数量 <span>{{ warehouseQua.freeWar }}</span>
              </div>
              <div class="hang">
                恒温仓库数量 <span>{{ warehouseQua.isotemperatureWar }}</span>
              </div>
              <div class="hang">
                冷藏仓库数量 <span>{{ warehouseQua.refrigeratedWar }}</span>
              </div>
            </div>
            <div class="col col3">
              <div class="b-smtit">
                <span class="bt">仓储产品数量</span>
              </div>
              <div class="chart" ref="chart7"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/head_big.vue";
let Echarts = require("echarts/lib/echarts");
import { loopShowTooltip } from "../assets/js/tooltip-auto-show-vue.js";
import axios from "axios";
export default {
  data() {
    return {
      headTitle: "",
      industryDetails: {},
      randomNum: 0,
      swiper: [],
      // wenmingList: [
      //   {
      //     img: require("../assets/images/wt12.png"),
      //   },
      // ],
      xiangcunList: [
        {
          img: "https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/gy/1.jpg",
        },
        {
          img: "https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/gy/2.jpg",
        },
        {
          img: "https://video-1316200666.cos.ap-guangzhou.myqcloud.com/kashi/gongye/3.gif",
        },
        {
          img: "https://video-1316200666.cos.ap-guangzhou.myqcloud.com/kashi/gongye/4.jpg",
        },
        {
          img: "https://video-1316200666.cos.ap-guangzhou.myqcloud.com/kashi/gongye/5.webp",
        },
        {
          img: "https://video-1316200666.cos.ap-guangzhou.myqcloud.com/kashi/gongye/6.webp",
        },
      ],
      chucunshuliangList: [],
      factoryList: [],
      yuanquList: [
        {
          title: "",
          cata: "",
          amount: "",
        },
        {
          title: "",
          cata: "",
          amount: "",
        },
        {
          title: "",
          cata: "",
          amount: "",
        },
        {
          title: "",
          cata: "",
          amount: "",
        },
        {
          title: "",
          cata: "",
          amount: "",
        },
      ],
      chart1: null,
      tootipTimer1: "",
      chart2: null,
      tootipTimer2: "",
      chart3: null,
      tootipTimer3: "",
      chart4: null,
      tootipTimer4: "",
      chart5: null,
      tootipTimer5: "",
      chart6: null,
      tootipTimer6: "",
      chart7: null,
      tootipTimer7: "",
      chart8: null,
      tootipTimer8: "",
      webData: {},
      productOrder: [],
      supplyTrend: {},
      modeofTra: [],
      logisticsCom: [],
      logisticsInd: {},
      sunhangLog: [],
      warehousePro: [],
      industryDetails: {},
      factoryList: [],
      industrialParks: [],
      dailyRan: [],
      warehouseQuantity: {},
      warehouseQua: {},
      industrialPark: {},
      loaded: false,
      ProductTra: {},
      millworkPlant: {},
      brandNum: {},
    };
  },
  components: {
    Head
  },
  computed: {
    scrollOption() {
      return {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {
    clearInterval();
    clearTimeout();
    this.getData();
    this.getmodeofTra();
    this.getlogisticsCom();
    this.getlogisticsInd();
    this.getsunhangLog();
    this.getwarehousePro();
  },
  mounted() {
    console.log("window.screen.height", window.screen.height);
    console.log("window.screen.width", window.screen.width);
    console.log("document.body.clientWidth", document.body.clientWidth);
    console.log("document.body.clientHeight", document.body.clientHeight);
    console.log("可视区域宽度", document.body.clientHeight);
    setInterval(() => {
      this.randomNum = Math.floor(Math.random() * 3);
    }, 3000);
    // this.init8()
    this.loaded = true;
    setTimeout(() => {
      this.getproductOrder();
      this.getsupplyTrend();
      this.getBrandNum();
      this.init8();
    }, 1000);
    window.addEventListener("resize", () => {
      this.chart1.resize();
      this.chart2.resize();
      this.chart3.resize();
      this.chart4.resize();
      this.chart5.resize();
      this.chart6.resize();
      this.chart7.resize();
      this.chart8.resize();
    });
    // this.swiper = new Swiper(".swiper-container.swiper-container1", {
    //   slidesPerView: 3,
    //   speed:3000,
    //   autoplay:true,
    //   spaceBetween: 30,
    //   centeredSlides: true,
    //   loop: true,
    // });
  },

  methods: {
    getData() {
      axios
        .get("/10ksxhds.json", {
          params: {
            // timestamp:new Date().getTime()
            random: Math.random(),
          },
        })
        .then((res) => {
          let dataObject = res.data;
          let jsonString = JSON.stringify(dataObject);
          let jsonObject = JSON.parse(jsonString);
          this.webData = jsonObject;
          this.headTitle = jsonObject.bigTitle;
          this.chucunshuliangList = jsonObject.dailyRan;
          this.factoryList = jsonObject.factoryList;
          this.yuanquList = jsonObject.industrialParks;
          console.log("jsonObject=========>", jsonObject);
          this.industryDetails = jsonObject.industryDetails;
          this.warehouseQua = jsonObject.warehouseQua;
          this.warehouseQuantity = jsonObject.warehouseQuantity;
          this.ProductTra = jsonObject.ProductTra;
          this.industrialPark = jsonObject.industrialPark;
          this.millworkPlant = jsonObject.millworkPlant;
        })
        .catch((error) => {
          console.log(error.response.status);
          console.log(error.response.data);
          console.log(error.response.headers);
        });
    },
    async getproductOrder() {
      const res = await axios.get("/10ksxhds.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.productOrder = jsonObject.productOrder;
      this.init1();
    },
    init1() {
      this.chart1 = Echarts.init(this.$refs.chart1);
      let chartData = [];
      chartData = this.productOrder;
      const sum = chartData.reduce((per, cur) => per + cur.value, 0);
      let option = {
        title: {
          text: "{a|各类工业增加值}",
          // text: "{a|各类工业增加值}\n\n{b|" + sum + "种}",
          top: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: nowSize(25),
                padding: [nowSize(-10), 0, 0, nowSize(110)],
                color: "#fff",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
              b: {
                fontSize: nowSize(25),
                padding: [nowSize(-10), 0, 0, nowSize(120)],
                color: "#45F5FF",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
            },
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(24),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
          containLabel: true,
        },
        legend: {
          x: "right",
          y: "center",
          padding: [0, 15, 0, 0],
          orient: "vertial",
          icon: "rect",
          itemWidth: nowSize(30),
          itemHeight: nowSize(20),
          textStyle: {
            color: "#fff",
            fontSize: nowSize(16),
            lineHeight: nowSize(30),
          },
          itemGap: nowSize(20),
          formatter: (name) => {
            console.log(name);
            console.log(chartData);
            const item = chartData.find((i) => {
              return i.name === name;
            });
            const p = ((item.value / sum) * 100).toFixed(0);
            const value = item.value;
            return "{name|" + name + "}" + "{percent|" + p + "%}"; //+ "{value|" + value + "种"+"}"
          },
          textStyle: {
            rich: {
              name: {
                fontSize: nowSize(12),
                height: nowSize(20),
                padding: [0, nowSize(15), 0, 0],
                color: "#fff",
              },

              value: {
                fontSize: nowSize(12),
                height: nowSize(20),
                padding: [0, nowSize(15), 0, 0],
                color: "#fc9d1f",
              },

              percent: {
                fontSize: nowSize(12),
                height: nowSize(20),
                color: "#fff",
              },
            },
          },
        },

        color: ["rgb(86,230,215)", "rgb(51,120,255)", "rgb(210,61,226)", "rgb(231,88,140)", "rgb(76,238,141)", "rgb(234,113,71)"],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["77%", "90%"],
            center: ["32%", "48%"],
            avoidLabelOverlap: false,
            hoverAnimation: true,
            label: {
              normal: {
                show: false,
              },
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#031232",
              borderWidth: nowSize(2),
            },
            labelLine: {
              show: false,
            },
            data: chartData,
          },
          {
            type: "pie",
            name: "内层细圆环",
            radius: ["61%", "63%"],
            center: ["32%", "48%"],
            hoverAnimation: false,
            clockWise: true,
            itemStyle: {
              normal: {
                color: "#48b1ea",
              },
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false,
            },
            data: [1],
          },
        ],
      };
      this.chart1.setOption(option, true);

      this.tootipTimer1 && this.tootipTimer1.clearLoop();
      this.tootipTimer1 = 0;
      this.tootipTimer1 = loopShowTooltip(this.chart1, option, {});
    },
    async getsupplyTrend() {
      const res = await axios.get("/10ksxhds.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.supplyTrend = jsonObject.supplyTrend;
      this.init2();
    },

    init2() {
      this.chart2 = Echarts.init(this.$refs.chart2);
      let option = {
        title: {
          text: "单位为:万吨",
          right: 5,
          top: 0,
          textStyle: {
            fontSize: nowSize(22),
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(24),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        toolbox: {
          show: true,
        },
        grid: {
          left: nowSize(20),
          right: nowSize(40),
          bottom: nowSize(10),
          top: nowSize(20),
          containLabel: true,
        },

        // legend: {
        // 	x: 'right',
        // 	y: 'top',
        // 	icon:'rect',
        // 	itemWidth:nowSize(14),
        // 	itemHeight:nowSize(14),
        // 	textStyle: {
        // 		color: '#fff',
        // 		fontSize: nowSize(18)
        // 	},
        // },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            // data: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
            data: this.supplyTrend.month,
            axisPointer: {
              type: "",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: nowSize(18),
              },
              //rotate: '0',
              interval: 0,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#023748", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#edf2f8"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            position: "left",
            min: 0,
            //max: 6000,
            // interval: 1000,
            nameTextStyle: {
              color: "#fff",
              fontSize: nowSize(18),
              padding: [0, 0, 0, -20],
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff", //更改坐标轴文字颜色
                fontSize: nowSize(18), //更改坐标轴文字大小
              },
              //formatter: '{value}%'
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#023748", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#023748"],
                width: 1,
                type: "solid",
              },
            },
          },
        ],

        series: [
          {
            name: "",
            type: "line",
            // data: [2000,2700,3000,2600,1800,1500,2100,3600,4100,2500,2000,3500],
            data: this.supplyTrend.data,
            smooth: true,
            symbolSize: 1, //折线点的大小
            itemStyle: {
              normal: {
                color: "#FD9D20", //折线点的颜色
                borderWidth: 1,
                lineStyle: {
                  color: "#FD9D20", //折线的颜色
                },
                areaStyle: {
                  color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "rgba(235,157,32,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(235,157,32,.6)",
                    },
                  ]),
                },
              },
            },
          },
        ],
      };
      this.chart2.setOption(option, true);

      this.tootipTimer2 && this.tootipTimer2.clearLoop();
      this.tootipTimer2 = 0;
      this.tootipTimer2 = loopShowTooltip(this.chart2, option, {});
    },
    async getmodeofTra() {
      const res = await axios.get("/10ksxhds.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.modeofTra = jsonObject.modeofTra;
      this.init3();
    },

    init3() {
      this.chart3 = Echarts.init(this.$refs.chart3);
      let chartData = [];
      chartData = this.modeofTra;
      const sum = chartData.reduce((per, cur) => per + cur.value, 0);
      let option = {
        title: {
          text: "{a|占比最大}\n\n{b|公路运输}",
          top: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: nowSize(20),
                padding: [0, 0, 0, nowSize(40)],
                color: "#fff",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
              b: {
                fontSize: nowSize(25),
                padding: [nowSize(-10), 0, 0, nowSize(30)],
                color: "#45F5FF",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
            },
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(24),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
          containLabel: true,
        },
        legend: {
          x: "right",
          y: "center",
          orient: "vertial",
          icon: "rect",
          itemWidth: nowSize(20),
          itemHeight: nowSize(20),
          textStyle: {
            color: "#fff",
            fontSize: nowSize(18),
            lineHeight: nowSize(30),
          },
          itemGap: nowSize(15),
          formatter: (name) => {
            console.log(name);
            console.log(chartData);
            const item = chartData.find((i) => {
              return i.name === name;
            });
            const p = ((item.value / sum) * 100).toFixed(0);
            const value = item.value;
            return (
              "{name|" +
              name +
              "}" +
              "{value|" +
              value +
              "万吨}"
              // "{percent|" +
              // p +
              // "%}"
            );
          },
          textStyle: {
            rich: {
              name: {
                fontSize: nowSize(18),
                height: nowSize(20),
                width: nowSize(80),
                // padding: [0, nowSize(15), 0, 0],
                color: "#fff",
              },
              value: {
                fontSize: nowSize(18),
                height: nowSize(20),
                width: nowSize(90),
                // padding: [0, nowSize(15), 0, 0],
                color: "#45F5FF",
              },
              percent: {
                fontSize: nowSize(18),
                height: nowSize(20),
                color: "#fff",
              },
            },
          },
        },
        color: ["rgb(86,230,215)", "rgb(51,120,255)", "rgb(210,61,226)"],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["50%", "63%"],
            center: ["20%", "48%"],
            avoidLabelOverlap: false,
            hoverAnimation: true,
            label: {
              normal: {
                show: false,
              },
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#031232",
              borderWidth: nowSize(2),
            },
            labelLine: {
              show: false,
            },
            data: chartData,
          },
          {
            type: "pie",
            name: "内层细圆环",
            radius: ["42%", "44%"],
            center: ["20%", "48%"],
            hoverAnimation: false,
            clockWise: true,
            itemStyle: {
              normal: {
                color: "#178ac9",
              },
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false,
            },
            data: [1],
          },
        ],
      };
      this.chart3.setOption(option, true);

      this.tootipTimer3 && this.tootipTimer3.clearLoop();
      this.tootipTimer3 = 0;
      this.tootipTimer3 = loopShowTooltip(this.chart3, option, {});
    },
    async getlogisticsCom() {
      const res = await axios.get("/10ksxhds.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.logisticsCom = jsonObject.logisticsCom;
      this.init4();
    },

    init4() {
      this.chart4 = Echarts.init(this.$refs.chart4);
      let chartData = [];
      chartData = this.logisticsCom;
      const sum = chartData.reduce((per, cur) => per + cur.value, 0);
      let option = {
        title: {
          text: "{a|金额}\n\n{b|" + sum + "万元}",
          top: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: nowSize(25),
                padding: [nowSize(-10), 0, 0, nowSize(110)],
                color: "#fff",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
              b: {
                fontSize: nowSize(30),
                padding: [nowSize(-10), 0, 0, nowSize(120)],
                color: "#45F5FF",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
            },
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(24),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
          containLabel: true,
        },
        legend: {
          x: "right",
          y: "center",
          orient: "vertial",
          icon: "rect",
          itemWidth: nowSize(20),
          itemHeight: nowSize(20),
          textStyle: {
            color: "#fff",
            fontSize: nowSize(16),
            lineHeight: nowSize(30),
          },
          itemGap: nowSize(20),
          formatter: (name) => {
            console.log(name);
            console.log(chartData);
            const item = chartData.find((i) => {
              return i.name === name;
            });
            const p = ((item.value / sum) * 100).toFixed(0);
            const value = item.value;
            return "{name|" + name + "}" + "{percent|" + p + "%}"; //+ "{value|" + value + "万个" +"}"
          },
          textStyle: {
            rich: {
              name: {
                fontSize: nowSize(25),
                height: nowSize(20),
                padding: [0, nowSize(15), 0, 0],
                color: "#fff",
              },

              value: {
                fontSize: nowSize(25),
                height: nowSize(20),
                padding: [0, nowSize(15), 0, 0],
                color: "#fc9d1f",
              },

              percent: {
                fontSize: nowSize(25),
                height: nowSize(20),
                color: "#fff",
              },
            },
          },
        },

        color: [
          "rgb(86,230,215)",
          "rgb(51,120,255)",
          "rgb(255,233,156)",
          "rgb(162,106,227)",
          "rgb(85,241,41)",
        ],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["62%", "75%"],
            center: ["35%", "48%"],
            avoidLabelOverlap: false,
            hoverAnimation: true,
            label: {
              normal: {
                show: false,
              },
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#031232",
              borderWidth: nowSize(2),
            },
            labelLine: {
              show: false,
            },
            data: chartData,
          },
          {
            type: "pie",
            name: "内层细圆环",
            radius: ["56%", "57%"],
            center: ["35%", "48%"],
            hoverAnimation: false,
            clockWise: true,
            itemStyle: {
              normal: {
                color: "#48b1ea",
              },
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false,
            },
            data: [1],
          },
        ],
      };
      this.chart4.setOption(option, true);

      this.tootipTimer4 && this.tootipTimer4.clearLoop();
      this.tootipTimer4 = 0;
      this.tootipTimer4 = loopShowTooltip(this.chart4, option, {});
    },
    async getlogisticsInd() {
      const res = await axios.get("/10ksxhds.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.logisticsInd = jsonObject.logisticsInd;

      console.log(this.logisticsInd.data);
      this.init5();
    },

    init5() {
      console.log( "------->",this.logisticsInd.data);
      this.chart5 = Echarts.init(this.$refs.chart5);
      let option = {
        title: {
          text: "单位为:万件",
          right: nowSize(10),
          top: nowSize(1),
          textStyle: {
            fontSize: nowSize(22),
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(24),
            color: "#fff",
          },
          extraCssText: "border:1px solid #FD9D20",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        toolbox: {
          show: true,
        },
        grid: {
          left: nowSize(20),
          right: nowSize(40),
          bottom: nowSize(10),
          top: nowSize(20),
          containLabel: true,
        },

        // legend: {
        // 	x: 'right',
        // 	y: 'top',
        // 	icon:'rect',
        // 	itemWidth:nowSize(14),
        // 	itemHeight:nowSize(14),
        // 	textStyle: {
        // 		color: '#fff',
        // 		fontSize: nowSize(18)
        // 	},
        // },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [
              "2021",
              "2022",
              "2023",
              // "4月",
              // "5月",
              // "6月",
              // "7月",
              // "8月",
              // "9月",
              // "10月",
              // "11月",
              // "12月",
            ],
            axisPointer: {
              type: "",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: nowSize(18),
              },
              //rotate: '0',
              interval: 0,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#023748", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#edf2f8"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            position: "left",
            min: 0,
            //max: 6000,
            // interval: 1000,
            nameTextStyle: {
              color: "#fff",
              fontSize: nowSize(18),
              padding: [0, 0, 0, -20],
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff", //更改坐标轴文字颜色
                fontSize: nowSize(18), //更改坐标轴文字大小
              },
              //formatter: '{value}%'
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#023748", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#023748"],
                width: 1,
                type: "solid",
              },
            },
          },
        ],

        series: [
          {
            name: "",
            type: "line",
            // data: [2000,2700,3000,2600,1800,1500,2100,3600,4100,2500,2000,3500],
            data: this.logisticsInd.data,
            smooth: true,
            symbolSize: 1, //折线点的大小
            itemStyle: {
              normal: {
                color: "#FD9D20", //折线点的颜色
                borderWidth: 1,
                lineStyle: {
                  color: "#FD9D20", //折线的颜色
                },
                areaStyle: {
                  color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "rgba(235,157,32,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(235,157,32,.6)",
                    },
                  ]),
                },
              },
            },
          },
        ],
      };
      this.chart5.setOption(option, true);

      this.tootipTimer5 && this.tootipTimer2.clearLoop();
      this.tootipTimer5 = 0;
      this.tootipTimer5 = loopShowTooltip(this.chart5, option, {});
    },
    async getsunhangLog() {
      const res = await axios.get("/10ksxhds.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.sunhangLog = jsonObject.sunhangLog;
      this.init6();
    },

    init6() {
      this.chart6 = Echarts.init(this.$refs.chart6);
      let chartData = [];
      chartData = this.sunhangLog;
      const sum = chartData.reduce((per, cur) => per + cur.value, 0);
      let option = {
        title: {
          text: "{a|物流件数}\n\n{b|" + sum + "万件}",
          top: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: nowSize(25),
                padding: [nowSize(-10), 0, 0, nowSize(110)],
                color: "#fff",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
              b: {
                fontSize: nowSize(30),
                padding: [nowSize(-10), 0, 0, nowSize(120)],
                color: "#45F5FF",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
            },
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(24),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
          containLabel: true,
        },
        legend: {
          x: "right",
          y: "center",
          orient: "vertial",
          icon: "rect",
          itemWidth: nowSize(20),
          itemHeight: nowSize(20),
          textStyle: {
            color: "#fff",
            fontSize: nowSize(16),
            lineHeight: nowSize(30),
          },
          itemGap: nowSize(20),
          formatter: (name) => {
            console.log(name);
            console.log(chartData);
            const item = chartData.find((i) => {
              return i.name === name;
            });
            const p = ((item.value / sum) * 100).toFixed(0);
            const value = item.value;
            return "{name|" + name + "}" + "{percent|" + p + "%}"; //+ "{value|" + value + "万个" +"}"
          },
          textStyle: {
            rich: {
              name: {
                fontSize: nowSize(25),
                height: nowSize(20),
                padding: [0, nowSize(15), 0, 0],
                color: "#fff",
              },

              value: {
                fontSize: nowSize(25),
                height: nowSize(20),
                padding: [0, nowSize(15), 0, 0],
                color: "#fc9d1f",
              },

              percent: {
                fontSize: nowSize(25),
                height: nowSize(20),
                color: "#fff",
              },
            },
          },
        },

        color: [
          "rgb(86,230,215)",
          "rgb(51,120,255)",
          "rgb(255,233,156)",
          "rgb(162,106,227)",
          "rgb(85,241,41)",
        ],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["52%", "65%"],
            center: ["35%", "48%"],
            avoidLabelOverlap: false,
            hoverAnimation: true,
            label: {
              normal: {
                show: false,
              },
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#031232",
              borderWidth: nowSize(2),
            },
            labelLine: {
              show: false,
            },
            data: chartData,
          },
          {
            type: "pie",
            name: "内层细圆环",
            radius: ["46%", "48%"],
            center: ["35%", "48%"],
            hoverAnimation: false,
            clockWise: true,
            itemStyle: {
              normal: {
                color: "#48b1ea",
              },
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false,
            },
            data: [1],
          },
        ],
      };
      this.chart6.setOption(option, true);

      this.tootipTimer6 && this.tootipTimer6.clearLoop();
      this.tootipTimer6 = 0;
      this.tootipTimer6 = loopShowTooltip(this.chart6, option, {});
    },
    async getwarehousePro() {
      const res = await axios.get("/10ksxhds.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.warehousePro = jsonObject.warehousePro;
      this.init7();
    },

    init7() {
      this.chart7 = Echarts.init(this.$refs.chart7);
      let chartData = [];
      chartData = this.warehousePro;
      const sum = chartData.reduce((per, cur) => per + cur.value, 0);
      let option = {
        title: {
          text: "{a|全年固定资产投资}\n\n{b|" + 670.42 + "亿}",
          top: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: nowSize(25),
                padding: [nowSize(-10), 0, 0, nowSize(100)],
                color: "#fff",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
              b: {
                fontSize: nowSize(25),
                padding: [nowSize(10), 0, 0, nowSize(100)],
                color: "#45F5FF",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
            },
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(24),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
          containLabel: true,
        },
        legend: {
          x: "right",
          y: "center",
          padding: [0, 20, 0, 0],
          orient: "vertial",
          icon: "rect",
          itemWidth: nowSize(20),
          itemHeight: nowSize(20),
          textStyle: {
            color: "#fff",
            fontSize: nowSize(16),
            lineHeight: nowSize(30),
          },
          itemGap: nowSize(20),
          formatter: (name) => {
            console.log(name);
            console.log(chartData);
            const item = chartData.find((i) => {
              return i.name === name;
            });
            const p = ((item.value / sum) * 100).toFixed(0);
            const value = item.value;
            return "{name|" + name + "}" + "{percent|" + p + "%}"; //+ "{value|" + value + "种"+"}"
          },
          textStyle: {
            rich: {
              name: {
                fontSize: nowSize(25),
                height: nowSize(20),
                padding: [0, nowSize(15), 0, 0],
                color: "#fff",
              },

              value: {
                fontSize: nowSize(25),
                height: nowSize(20),
                width: nowSize(50),
                padding: [0, nowSize(15), 0, 0],
                color: "#fc9d1f",
              },

              percent: {
                fontSize: nowSize(25),
                height: nowSize(20),
                color: "#fff",
              },
            },
          },
        },

        color: ["rgb(86,230,215)", "rgb(51,120,255)", "rgb(210,61,226)"],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["77%", "90%"],
            center: ["30%", "48%"],
            avoidLabelOverlap: false,
            hoverAnimation: true,
            label: {
              normal: {
                show: false,
              },
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#031232",
              borderWidth: nowSize(2),
            },
            labelLine: {
              show: false,
            },
            data: chartData,
          },
          {
            type: "pie",
            name: "内层细圆环",
            radius: ["61%", "63%"],
            center: ["30%", "48%"],
            hoverAnimation: false,
            clockWise: true,
            itemStyle: {
              normal: {
                color: "#48b1ea",
              },
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false,
            },
            data: [1],
          },
        ],
      };
      this.chart7.setOption(option, true);

      this.tootipTimer7 && this.tootipTimer7.clearLoop();
      this.tootipTimer7 = 0;
      this.tootipTimer7 = loopShowTooltip(this.chart7, option, {});
    },
    async getBrandNum() {
      const res = await axios.get("/10ksxhds.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.brandNum = jsonObject.brandNum;
      console.log("this.brandNum===>", this.brandNum);
      this.init8();
    },
    init8() {
      this.chart8 = Echarts.init(this.$refs.chart8);
      let option = {
        title: {
          text: "单位:亿",
          top: nowSize(1),
          right: nowSize(1),
          textStyle: {
            fontSize: nowSize(24),
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(24),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        toolbox: {
          show: true,
        },
        grid: {
          left: nowSize(10),
          right: nowSize(10),
          bottom: nowSize(0),
          top: nowSize(20),
          containLabel: true,
        },
        legend: {
          top: 0,
          x: 80,
          textStyle: {
            color: "#fff",
            fontSize: nowSize(24),
          },
          itemWidth: nowSize(22),
          itemHeight: nowSize(8),
          icon: "rect",
        },
        xAxis: [
          {
            type: "category",
            //boundaryGap: false,
            // data: ["2020年","2021年","2022年"],
            data: this.brandNum.year,
            axisPointer: {
              type: "",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: nowSize(20),
              },
              //rotate: '0',
              interval: 0,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#024570", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#edf2f8"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            position: "left",
            min: 0,
            // max: 500,
            interval: 20,
            nameTextStyle: {
              color: "#fff",
              fontSize: nowSize(20),
              padding: [0, 0, 0, -20],
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff", //更改坐标轴文字颜色
                fontSize: nowSize(20), //更改坐标轴文字大小
              },
              //formatter: '{value}%'
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#45F5FF", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#45F5FF"],
                width: 1,
                type: "solid",
              },
            },
          },
        ],

        series: [
          {
            name: "工业增加值",
            type: "bar",
            barWidth: nowSize(35),
            // data: [292, 219, 285],
            data: this.brandNum.ppNum,
            itemStyle: {
              normal: {
                // barBorderRadius: 5,
                borderWidth: 1,
                color: "#2dd6d8",
                lineStyle: {
                  color: "#2dd6d8", //折线的颜色
                },
              },
            },
          },
          {
            name: "建筑业增加值",
            type: "bar",
            barWidth: nowSize(35),
            // data: [292, 219, 285],
            data: this.brandNum.owerNum,
            itemStyle: {
              normal: {
                barBorderRadius: 0,
                color: "#fd9d20", //折线点的颜色
                borderWidth: 1,
                lineStyle: {
                  color: "#fd9d20", //折线的颜色
                },
              },
            },
          },
          ,
          // {
          //   name: "农民合作社年报数量",
          //   type: "bar",
          //   barWidth: nowSize(35),
          //   // data: [292, 219, 285],
          //   data: this.brandNum.farmerNum,
          //   itemStyle: {
          //     normal: {
          //       color: "#fd9d20", //折线点的颜色
          //       borderWidth: 1,
          //       lineStyle: {
          //         color: "#fd9d20", //折线的颜色
          //       },
          //     },
          //   },
          // },
        ],
      };
      this.chart8.setOption(option, true);
      this.tootipTimer8 && this.tootipTimer8.clearLoop();
      this.tootipTimer8 = 0;
      this.tootipTimer8 = loopShowTooltip(this.chart8, option, {});
    },
  },
};
</script>

<style scoped>
.page {
  background: url(https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/background.png) no-repeat center;
  background-size: 100% 100%;
  background-color: #0a224b;
}

.mod2-map .pic {
  opacity: 0.8;
  z-index: 0;
  width: 100%;
  height: 100%;
  max-width: initial;
}
.mod1 {
  height: 70%;
}
/* .mod2 {
  height: 40%;
  margin-bottom: 10px;
} */
.mod3 {
  margin-top: 5%;
  height: 45%;
}
.mod4 {
  height: 52%;
  margin-bottom: 1%;
}
.mod5 {
  height: 47%;
}
/* .mod7 {
  height: calc(27% - 165px);
} */

.mod1-di {
  display: flex;
  justify-content: space-between;
}

.mod1-b {
  margin-top: 2%;
    height: 38%;
  display: flex;
  justify-content: space-around;
}
.mod1-b .chart {
  /* margin-top: 3%; */
  height: calc(100% - 20px);
  flex: 1;
}
.mod1-b .col {
  width: 50%;
  height: 100%;
}
.mod1-b .b-smtit {
  margin-bottom: 0;
}

.mod1-b .grp {
  margin-top: 40px;
}
.mod1-b .grp .num {
  font-size: 24px;
  font-family: "YouSheBiaoTiHei";
}
.mod1-b .grp .fz {
  font-size: 18px;
  color: #fff;
}
.mod1-b .tip {
  font-size: 20px;
  color: #fff;
  margin-top: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mod1-b .tip .star {
  width: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.mod1-b .tip .wz {
  font-size: 20px;
  color: red;
  margin-left: 5px;
}
.mod2-b {
  height: 60%;
  margin-top: 5px;
}
.mod2-b .col {
  width: 100%;
  height: 100%;
}
/* .mod2-b .col1 {
  width: 40%;
  height: 40%;
}
.mod2-b .col2 {
  width: 50%;
}
.mod2-b .col3 {
  width: 27%;
  padding-left: 1%;
} */
.mod2-b .chart {
  /* height: calc(100% - 100px); */
  /* margin-top: 3%; */
  height: calc(85% - 10px);
  flex: 1;
}
.mod-camera {
  display: none;
}
.mod2-bot {
  z-index: 99;
  position: absolute;
  width: calc(100% + 70px);
  left: -35px;
  bottom: 50px;
  display: flex;
  justify-content: space-between;
}
.mod2-bot .b-smtit {
  margin-bottom: 10px;
}
.mod2-bot .col {
  background-color: rgba(0, 48, 104, 0.21);
  border-radius: 10px;
  height: 300px;
  padding: 15px;
}
.mod2-bot .col1 {
  width: 32%;
}
.mod2-bot .col2 {
  width: 34%;
}
.mod2-bot .col3 {
  width: 34%;
}
.mod2-bot .chart {
  height: calc(90% - 10px);
}

.mod2-map {
  position: absolute;
  width: calc(100% + 300px);
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  margin-left: -150px;
}
.mod2-map .item {
  width: 400px;
  padding: 20px;
  border-radius: 10px;
  background: rgba(0, 48, 104, 0.48);
  position: absolute;
  opacity: 0;
}
.mod2-map .item .txt {
  font-size: 30px;
  color: #45f5ff;
  line-height: 50px;
  padding-top: 7px;
}
.mod2-map .item .txt p {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.mod2-map .item .tit {
  color: #fff;
}
.mod2-map .item .txt * {
  font-family: "YouSheBiaoTiHei";
}

.mod2-map .item1 {
  right: 15%;
  top: 20%;
}
.mod2-map .item2 {
  left: 10%;
  top: 30%;
}
.mod2-map .item3 {
  right: 20%;
  top: 40%;
}

.mod3 {
  width: calc(100% + 50px);
  /* height: 50%; */
}
.mod3 .list {

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.mod3 .list .item1 {
  /* display: flex;
  align-items: end; */
  margin-top: 2%;
  width: 33%;
}
.mod3 .imgbox .img_full {
  width: 100%;
}
.mod3 .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.8);
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
}

/* .mod3 .list .item1 .imgbox {
  height: 70%;
  width: 130%;
} */
.mod3 .list .imgbox {
  /* width: 210px; */
  height: 135px;
  border: 4px solid #041f48;
  border-radius: 8px;
  margin-right: 10px;
}
.mod3 .list .info {
  flex: 1;
  overflow: hidden;
}
.mod3 .list .title {
  font-size: 21px;
  color: #fff;
  margin-bottom: 5px;
}
.mod3 .list .bot {
  display: flex;
  align-items: center;
}
.mod3 .list .num {
  font-size: 24px;
  color: #45f5ff;
  font-family: "YouSheBiaoTiHei";
}
.mod3 .list .level {
  font-size: 22px;
  color: #00ffb5;
  height: 34px;
  line-height: 34px;
  border: 1px solid #00ffb5;
  border-radius: 6px;
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  padding: 0 8px;
  font-family: "YouSheBiaoTiHei";
}

.mod4-b {
  height: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.mod4-b .b-smtit {
  margin-bottom: 0;
}
.mod4-b .col {
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.mod4-b .col2 .bar-list {
  margin-top: 3%;
}
.mod4-b .chart {
  height: calc(95% - 10px);
  flex: 1;
}
.mod4-b .bar-list .row {
  margin-bottom: 15px;
  /* padding: 11px; */
}
.mod4-b .bar-list .row .num {
  padding: 10px;
  font-size: 35px;
}

/* .mod5-gk li {
  width: 33%;
  display: flex;
  align-items: center;
} */
.mod5-gk .icon {
  width: 80px;
}
.mod5-gk .info {
  flex: 1;
  overflow: hidden;
}
.mod5-gk .tit {
  font-size: 20px;
  color: #fff;
  white-space: nowrap;
}
.mod5-gk .num {
  font-size: 26px;
  color: #fff;
  font-family: "YouSheBiaoTiHei";
  white-space: nowrap;
}

.mod5-sl {
  margin-top: 3%;
  display: flex;
  justify-content: space-around;
}
.mod5-sl .col {
  width: 50%;
}

.mod5-sl .hang span {
  font-size: 30px;
  font-family: "YouSheBiaoTiHei";
  margin-left: 15px;
}
.mod5-sl .hang:nth-child(2) span {
  color: #ffc55a;
}
.mod5-sl .hang:nth-child(3) span {
  color: #90ffad;
}
.mod5-sl .hang:nth-child(4) span {
  color: #14c1fd;
}
.mod5-gk {
  min-height: 10vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.mod5-gk li {
  width: 33%;
  display: flex;
  align-items: center;
}
.mod5-sl .bar-list .row {
  margin-bottom: 15px;
}
.mod5-sl .chart {
  /* height: calc(100% - 40px); */
  height: calc(90% - 10px);
}
.mod5-sl .hang {
  font-size: 30px;
  color: #fff;
  line-height: 24px;
  padding: 20px;
}

.con-c {
  position: relative;
}

.cen-top.b {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 30px;
  padding: 0 5%;
}

.cm {
  color: #ffd105;
}
.cy {
  color: #015aff;
}
/* 福安市 小于2560px */
@media screen and (max-width: 2560px) {
  .mod3 .list .imgbox {
    height: 210px;
  }
  .mod3 .list .item1{
    margin-top: 3%;

}
  .mod-camera {
    display: block;
  }
  .mod3 {
    height: 30%;
    margin-top: 2%;
  }
  .mod4-b .bar-list .row {
    margin-bottom: 15px;
    padding: 11px;
  }
  .mod5-gk {
    margin-top: 1%;
    min-height: 10vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .mod5-gk li {
    width: 50%;
    margin-top: 3%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .mod4-b .col2 .bar-list {
    /* width: 55%;
 height: 70%; */
    margin-top: 10%;
  }
  .mod1-b .chart {
    /* margin-top: 5%; */
    height: calc(70%);
    /* height: 400px; */

  }
  .mod5-sl .chart {
    height: calc(100% - 4px);
    /* height: calc(90% - 10px); */
  }
  .mod5-sl .hang {
    /* display: flex;
 justify-content: space-between; */
    font-size: 30px;
    color: #fff;
    line-height: 24px;
    padding: 30px;
  }
}
/* 报业 大于3840px */
/* @media screen and (min-width: 3840px) {
  .mod1{
    height: 60%;
  }


} */
</style>
