import { render, staticRenderFns } from "./05dianshang.vue?vue&type=template&id=39dd05c7&scoped=true&"
import script from "./05dianshang.vue?vue&type=script&lang=js&"
export * from "./05dianshang.vue?vue&type=script&lang=js&"
import style0 from "./05dianshang.vue?vue&type=style&index=0&id=39dd05c7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39dd05c7",
  null
  
)

export default component.exports